import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../../container/main'
import SEO from '../../../components/seo'
import Container from '../../../components/container'
import ContentBannerWithCTA from '../../../components/content-banner-with-cta'
import { heroContainer, contentBlock } from './index.module.scss'
import { UseCaseBlock, UseCaseCategory, UseCaseHero, UseCasesContainer, UseCaseTeaser } from '../../../components/content-use-cases'
import ContentBlockSecondary from '../../../components/content-block-secondary'
import ContentBlockSmall from '../../../components/content-block-small'
import ContentBlockLargeWithGallery from '../../../components/content-block-large-gallery'
import Subheadline from '../../../components/content-subheadline'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="Boost your employee productivity" />

    <Container fullWidth style={{ background: 'var(--primary)' }}>
      <UseCaseCategory title="Employee Productivity" />
    </Container>

    <Container>
      <UseCaseHero
        title="Boost your employee productivity with a Digital Hub"
        description="For a company to run well, the performance of its employees is the most important thing. However, keeping employees productive in the workplace is a ongoing challenge because digital work has changed massively in recent years. The number of apps has multiplied and the flood of information has increased. There is a risk that employees will be overwhelmed and frustrated. A Digital Hub where employees can find everything in one central solution helps to improve their workflows. When employees are more productive a company can grow its business."
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup-basaas-2022&utm_content=lp-employee-productivity"
        demoButton
      />
    </Container>

    <Container style={{ padding: '80px 0 0' }}>
      <Subheadline headline="Your digital headquarters to increase productivity in the workplace" />
      <span style={{ display: 'block', marginTop: '-8px', fontSize: '16px', fontWeight: '600', color: 'var(--primary)' }}>
        Empower your employees to optimize their workflows and offer a Digital Hub where they can find everything like apps, events, tasks and more.
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'MyDay',
            title: 'Organize the entire daily business',
            description: `Employees receive notifications from daily business and relevant news in a central place and can easily optimize their workflows.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-myday.png" alt="Organize the entire daily business" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Apps',
            title: 'All your apps in one solution',
            description: `Combine all apps for your people into your digital headquarters and help them to optimize their digital daily business.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-apps.png" alt="All your apps in one solution" placeholder="blurred" />,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Search',
            title: 'Cross app search to find everything',
            description: `Your staff can find information faster with an overarching search that searches your connected apps like Outlook, Teams, OneDrive, Sharepoint, etc.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-search.png" alt="Cross app search to find everything" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Tasks',
            title: 'All spread tasks in one solution',
            description: `Tasks are distributed across almost all apps. Your people get an overview of all these tasks in one central place and can act more confidently.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-tasks.png" alt="All spread tasks in one solution" placeholder="blurred" />,
            // comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>Explore how to optimize your business for</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
